import React from 'react';
import cx from 'classnames';
import { TransitionOnEnter, TRANSITIONS } from '../helpers';
import { Container, Grid, Cell } from 'components/Grid';

import gridStyles from 'scss/grid.module.scss';
import styles from './About.module.scss';
import Image from '../Layout/Image';

import { Heading, Section } from '../Layout';
import { ContactButton } from 'components/Buttons';
import logoLarge from 'assets/images/cindersoft-logo-large.jpg';
import logoLarge2x from 'assets/images/cindersoft-logo-large@2x.jpg';

const About = () => (
  <Section name='about'>
    <Container>
      <Grid margin={'-15px'} columns={12}>
        <Cell padding={15} width={{ xs: 6, md: 4 }} push={{ xs: 3, md: 4 }}>
          <TransitionOnEnter transition={TRANSITIONS.GROW}>
            <Image
              height='432'
              width='594'
              src={logoLarge}
              srcSet={`${logoLarge} 350w, ${logoLarge2x} 700w`}
              alt='Cindersoft logo'
            />
          </TransitionOnEnter>
        </Cell>
        <Cell padding={15} width={{ xs: 12 }}>
          <Heading
            tagLevel={1}
            styleLevel={2}
            classes={cx(styles.aboutHeading, gridStyles.marginTop0)}
          >
            <strong>Who we are</strong>
          </Heading>
          <Heading styleLevel={4} tagLevel={2}>
            Create innovative software solutions that ignite your business
            growth
          </Heading>
          <p>
            Cindersoft is all about delivering top-notch software development
            services to help businesses achieve their goals. By choosing
            Cindersoft, you'll benefit from a personalized approach and tailored
            solutions that meet your specific business needs.
          </p>
          <Heading
            tagLevel={1}
            styleLevel={2}
            classes={cx(styles.aboutHeading, gridStyles.marginTop0)}
          >
            <strong>Our services</strong>
          </Heading>
          <Heading styleLevel={4} tagLevel={2}>
            Spark your website with modern web development and design
          </Heading>
          <ul className={gridStyles.marginBottom2x}>
            <li>
              High-performance, responsive, and visually appealing web
              development services.
            </li>
            <li>Integration with CMS or e-commerce platforms.</li>
            <li>
              Accessibility services to comply with WCAG and ensure access for
              all.
            </li>
            <li>
              Migration and modernization services to adopt newer technologies.
            </li>
            <li>
              Ongoing support and maintenance services for optimal website
              performance over time.
            </li>
            <li>In-depth software development training and coaching.</li>
          </ul>
          <div className={gridStyles.textCenter}>
            <ContactButton>Email for a consultation</ContactButton>
          </div>
        </Cell>
      </Grid>
    </Container>
  </Section>
);

export default About;
