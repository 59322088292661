import React, { useRef, useState } from 'react';
import cx from 'classnames';
import styled, { css } from 'styled-components';
import { TransitionOnEnter, TRANSITIONS } from '../helpers';
import Link from '../helpers/Link';
import { Container, Grid, Cell } from 'components/Grid';
import { resumeData } from 'store/resume';

import gridStyles from 'scss/grid.module.scss';
import styles from './Experience.module.scss';

import { Heading, Section } from '../Layout';

const TrackPositionHorizontal = styled.div`
  width: ${(props) => 100 / props.count}%;
  transform: translateX(${(props) => 100 * props.position}%);
`;
const TrackPositionVertical = styled.div`
  height: ${(props) => 100 / props.count}%;
  transform: translateY(${(props) => 100 * props.position}%);
`;

const { length: positionsLength } = resumeData.experienceSection.positions;

const Experience = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabListRef = useRef(null);

  const setTab = ({ i, key }) => {
    if (key === 'ArrowLeft') {
      const newIndex = i === 0 ? positionsLength - 1 : i - 1;
      tabListRef.current.children[newIndex].focus();
      setSelectedIndex(newIndex);
    }
    if (key === 'ArrowRight') {
      const newIndex = i + 1 === positionsLength ? 0 : i + 1;
      tabListRef.current.children[newIndex].focus();
      setSelectedIndex(newIndex);
    }
  };

  return (
    <Section name='experience'>
      <Container>
        <Grid margin={'-15px'} columns={12}>
          <Cell padding={15} width={{ xs: 12, lg: 10 }} push={{ lg: 1 }}>
            <TransitionOnEnter transition={TRANSITIONS.SLIDE_LEFT}>
              <Grid margin={'-15px'} columns={12}>
                <Cell padding={15} width={{ xs: 12, lg: 10 }} push={{ lg: 1 }}>
                  <Heading
                    styleLevel={2}
                    classes={cx(styles.highlight, gridStyles.marginTop0)}
                  >
                    Experience
                  </Heading>
                </Cell>
              </Grid>
              <div className={styles.experienceContent}>
                <Grid margin={'-15px'} columns={12}>
                  <Cell padding={15} width={{ xs: 12, md: 2 }} push={{ lg: 1 }}>
                    <div className={styles.tabs}>
                      <div
                        className={styles.tabContainer}
                        role='tablist'
                        aria-label='Job tabs'
                        ref={tabListRef}
                      >
                        {resumeData.experienceSection.positions.map(
                          ({ company }, i) => {
                            const isSelected = i === selectedIndex;
                            return (
                              <button
                                key={`${company}-button`}
                                id={`tab-${i + 1}`}
                                className={cx(styles.tabButton, {
                                  [styles.tabButtonSelected]: isSelected,
                                })}
                                aria-selected={isSelected}
                                aria-controls={`panel-${i + 1}`}
                                role='tab'
                                tabIndex={i === selectedIndex ? 0 : -1}
                                onClick={() => setSelectedIndex(i)}
                                onKeyDown={({ key }) => {
                                  setTab({ i, key });
                                }}
                              >
                                {company}
                              </button>
                            );
                          },
                        )}
                        <div className={styles.tabTrack}>
                          <TrackPositionHorizontal
                            count={
                              resumeData.experienceSection.positions.length
                            }
                            position={selectedIndex}
                            className={cx(
                              styles.tabTrackPosition,
                              styles.tabTrackPositionHorizontal,
                            )}
                          />
                          <TrackPositionVertical
                            count={
                              resumeData.experienceSection.positions.length
                            }
                            position={selectedIndex}
                            className={cx(
                              styles.tabTrackPosition,
                              styles.tabTrackPositionVertical,
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </Cell>
                  <Cell width={{ xs: 12, md: 10, lg: 8 }}>
                    {resumeData.experienceSection.positions.map(
                      ({ company, roles, url }, i) => {
                        const isSelected = i === selectedIndex;
                        return (
                          <div
                            key={`${company}-panel`}
                            id={`panel-${i + 1}`}
                            className={cx(styles.experiencePanel, {
                              [styles.experiencePanelHidden]:
                                i !== selectedIndex,
                            })}
                            aria-labelledby={`tab-${i + 1}`}
                            role='tabpanel'
                            tabIndex='0'
                            {...(isSelected ? {} : { hidden: true })}
                          >
                            {roles.map(
                              ({
                                title,
                                startDate,
                                endDate,
                                bullets,
                                techStack = null,
                              }) => {
                                return (
                                  <div key={`${company}-${title}`}>
                                    <Heading
                                      styleLevel={4}
                                      tagLevel={1}
                                      classes={cx(styles.positionHeading)}
                                    >
                                      <strong>{title}</strong> at{' '}
                                      <Link
                                        href={url}
                                        className={cx(
                                          styles.companyLink,
                                          styles.highlightSecondary,
                                        )}
                                      >
                                        <strong>{company}</strong>
                                      </Link>
                                    </Heading>
                                    <Heading
                                      styleLevel={5}
                                      tagLevel={2}
                                      classes={cx(
                                        styles.positionHeadingSecondary,
                                        gridStyles.marginTop0,
                                        gridStyles.marginBottom,
                                      )}
                                    >
                                      {startDate} - {endDate}
                                    </Heading>
                                    <ul className={styles.ul}>
                                      {bullets.map((bullet, i) => (
                                        <li
                                          className={styles.li}
                                          key={`${company}-${title}-bullet-${i}`}
                                        >
                                          <span>{bullet}</span>
                                        </li>
                                      ))}
                                      {!techStack ? null : (
                                        <li className={styles.stackBullet}>
                                          <span>
                                            <span
                                              className={cx(
                                                styles.stack,
                                                styles.highlightSecondary,
                                              )}
                                            >
                                              Tech
                                            </span>
                                            : {techStack}
                                          </span>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                );
                              },
                            )}
                          </div>
                        );
                      },
                    )}
                  </Cell>
                </Grid>
              </div>
            </TransitionOnEnter>
          </Cell>
        </Grid>
      </Container>
    </Section>
  );
};

export default Experience;
