import React, { FC, useState } from 'react';

import { About } from 'components/Home';
import { Nav } from 'components/navigation';
import styles from 'scss/index.module.scss';
import { BaseTemplate } from 'templates';

const Index: FC = () => {
  const [isActive, setIsActive] = useState(false);

  const showNavbar = (shouldShowNavBar) => {
    setIsActive(shouldShowNavBar);
  };

  return (
    <BaseTemplate
      metaTitle='Cindersoft - Responsive Web Development & Design'
      metaDescription='Spark your website with modern web development and design. Partner with Cindersoft for custom front-end development services. Contact us today!'
      ogDescription='Spark your website with modern web development and design'
    >
      <div className={styles.index}>
        <Nav isSticky isHidden={!isActive} {...{ isActive }} />
        <Nav isHidden={isActive} {...{ showNavbar }} />
        <div className={styles.indexMain}>
          <About />
        </div>
      </div>
    </BaseTemplate>
  );
};

export default Index;
